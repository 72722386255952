// Define all initial states in the app
export const initialState = {
    auth: {
        userName: null,
        email: null,
        samAccountName: null,
        id_token: null,
    },
    general: {
        showUserInfo: false,
        isEditing: false,
        selectedCustomisationTab: 0,
        showNotifications: false,
        showChatField: false,
        showFeedbackForm: false,
        showFeedbackPrompt: true,
        checkFeedbackAPI: false,
    },
    pwa: {
        deferredPrompt: null,
        showInstallDialog: false,
    },
    alerts: {
        snackbarQueue: [],
    },
    error: null,
    taskCount: {
        itscApprovals: 0,
        approvals: 0,
        ptpApprovals: 0,
        ptpRequestItemTasks: 0,
    },
};
