export const APP_NAME = "MySSE.net";

// Transitions

// Media query keywords
export const MOBILE = "MOBILE";
export const DESKTOP = "DESKTOP";

// Tiles
export const REMOVED_TILE_PRIORITY = 999999;

// Notifications
export const NOTIFICATION_COUNT_PER_PAGE = 20;

// User profile
export const USER_PROFILE_EXPIRY_BUFFER_MS = 60000;

// iBuy
// Carts
export const FAVOURITES_CART_NAME = "favourites";

// Requisition statuses
export const APPROVED = "APPROVED";
export const AWAITING_APPROVAL = "AWAITING APPROVAL";
export const CANCELLED = "CANCELLED";
export const CANCELLATION_SUBMITTED = "CANCELLATION SUBMITTED";
export const CREATED = "CREATED";
export const INCOMPLETE = "INCOMPLETE";
export const INVALID_REQUISITION = "INVALID REQUISITION";
export const IN_PROCESS = "IN PROCESS";
export const PRE_APPROVED = "PRE-APPROVED";
export const PO_APPROVAL_PENDING = "PO APPROVAL PENDING";
export const PENDING = "PENDING";
export const SUBMITTED = "SUBMITTED";
export const AMENDMENT_SUBMITTED = "AMENDMENT SUBMITTED";
export const FAILED = "FAILED";
export const FULLY_RECEIPTED = "FULLY RECEIPTED";
export const RECEIPTING_NOT_REQUIRED = "NOT REQUIRED";
export const RETURNED = "RETURNED";
export const ATTENTION_REQUIRED = "ATTENTION REQUIRED";
export const NOT_RECEIPTED = "NOT RECEIPTED";
export const PARTIALLY_RECEIPTED = "PARTIALLY RECEIPTED";
export const RECEIPTING_IN_FLIGHT = "IN FLIGHT";
export const REJECTED = "REJECTED";
export const PO_NOT_AVAILABLE = "PO NOT AVAILABLE";
export const UNKNOWN_STATUS = "UNKNOWN";

// ServiceNow request state statuses
export const SN_REQUEST_PENDING_APPROVAL = "Pending Approval";
export const SN_REQUEST_APPROVED = "Approved";
export const SN_REQUEST_CLOSED_COMPLETE = "Closed Complete";
export const SN_REQUEST_CLOSED_INCOMPLETE = "Closed Incomplete";
export const SN_REQUEST_CLOSED_CANCELLED = "Closed Cancelled";
export const SN_REQUEST_CLOSED_REJECTED = "Closed Rejected";

//ServiceNow Incident statuses
export const INCIDENT_NEW = "New";
export const INCIDENT_IN_PROGRESS = "In Progress";
export const INCIDENT_ON_HOLD = "On Hold";
export const INCIDENT_RESOLVED = "Resolved";
export const INCIDENT_CLOSED = "Closed";
export const INCIDENT_CANCELLED = "Cancelled";

// Tile layout
export const REQUISITION_ROWS_PER_PAGE = 4;
export const REQUISITIONS_TO_LOAD = 20;

// Service Now requests tile
export const SERVICENOW_REQUEST_ROWS_PER_PAGE = 4;
export const SERVICENOW_INCIDENT_ROWS_PER_PAGE = 4;

// The ServiceNow API returns request items with short descriptions truncated after 160 characters.
export const SERVICENOW_REQUEST_ITEM_LENGTH_CUTOFF = 160;
// ----------
export const PROCURE_TO_PAY_INCIDENT_ROWS_PER_PAGE = 4;

//Tasks
export const MAX_TASKS_IN_CAROUSEL = 20;

// A11y
export const MIN_ALLOWED_CONTRAST = 2;

//ChatBot
export const CHATBOT_ERROR_MESSAGE =
    "Apologies, I didn't get that! Please try again.";
export const HTTP_PLACEHOLDER = "HTTP_PLACEHOLDER";
export const HTTPS_PLACEHOLDER = "HTTPS_PLACEHOLDER";
export const PERCENTAGE_PLACEHOLDER = "PERCENTAGE_PLACEHOLDER";
export const FORWARD_SLASH_PLACEHOLDER = "FORWARD_SLASH_PLACEHOLDER";
export const HYPHEN_PLACEHOLDER = "HYPHEN_PLACEHOLDER";

export const MAX_CHAT_HISTORY = 5;

// NPS
export const FEEDBACK_PER_PAGE = 10;

//Edison Admin
export const EDISON_QA_PER_PAGE = 10;

export const sharepointProcessorContentReplacements = {
    [PERCENTAGE_PLACEHOLDER]: "%",
    [FORWARD_SLASH_PLACEHOLDER]: "/",
    [HYPHEN_PLACEHOLDER]: "-",
};
