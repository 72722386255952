// Task name constants as per preferences API
import IBuyTaskCard from "../components/tasks/iBuyTasks/iBuyTaskCard";
import ServiceNowTaskCard from "../components/tasks/serviceNowTasks/serviceNowTaskCard";
import ProcureToPayTaskCard from "../components/tasks/serviceNowTasks/procureToPayTaskCard";
import PtpRequestItemTaskCard from "../components/tasks/serviceNowTasks/procureToPayRequestItemTaskCard";

export const PROCUREMENT_APPROVALS = "procurementApprovals";
export const SERVICE_NOW_APPROVALS = "servicenowApprovals";
export const PROCURE_TO_PAY_APPROVALS = "procuretopayApprovals";
export const PROCURE_TO_PAY_REQUEST_ITEMS = "procuretopayRequestItems";

export const taskTypeToCard = {
    [PROCUREMENT_APPROVALS]: IBuyTaskCard,
    [SERVICE_NOW_APPROVALS]: ServiceNowTaskCard,
    [PROCURE_TO_PAY_APPROVALS]: ProcureToPayTaskCard,
    [PROCURE_TO_PAY_REQUEST_ITEMS]: PtpRequestItemTaskCard,
};
