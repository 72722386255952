import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useIdleTimer } from "react-idle-timer";
import withAppInsights from "./appInsights";
import { ThemeProvider } from "@mui/material/styles";
import {
    ErrorBoundary,
    GenericErrorPage,
    IdleTimeoutDialog,
    Layout,
    lightTheme,
    LoadingScreen,
    millisToMinutesAndSeconds,
} from "@sse-digital-services/design-system";
import usePreloading from "./services/apiUtils/hooks/usePreloading";
import PageRouter from "./pageRouter";
import SfHeaderBarContent from "./components/headerBar/sfHeaderBarContent";
import SfBottomNavigationBar from "./components/navigation/sfBottomNavigationBar";
import useErrorHandling from "./errors/useErrorHandling";
import useAccessibility from "./services/apiUtils/hooks/useAccessibility";
import { triggerSnackbar } from "./utils/alertsUtils";
import { serviceNames } from "./constants/serviceNames";
import { useDispatch } from "react-redux";
import usePreferences from "./services/apiUtils/hooks/usePreferences";
import useSfThemes from "./utils/useSfThemes";
import SfSnackBar from "./components/alerts/snackBars/sfSnackBar";
import { APP_NAME } from "./constants/generalConstants";
import NpsBanner from "./components/modals/NPS/npsBanner";
import NpsDrawer from "./components/modals/NPS/npsDrawer";
import NpsButton from "./components/modals/NPS/npsButton";
import { ChatBotDrawer } from "./components/chatBot/chatBotDrawer";
import SfNavigationBar from "./components/navigation/sfNavigationBar";
import { CssBaseline } from "@mui/material";

function App() {
    // Collect states and state dispatchers
    const [isPrompted, setIsPrompted] = useState(false);
    const [remainingTime, setRemainingTime] = useState(null);
    const [intervalId, setIntervalId] = useState(null);
    const { servicesInMaintenanceMode } = usePreferences();
    const { themeMode } = useAccessibility();
    const { hasLoaded } = usePreloading();
    const { getTheme } = useSfThemes();
    const selectedTheme = getTheme(themeMode) ?? lightTheme;
    const { errorState, handleErrors, resetErrors } = useErrorHandling();
    const dispatch = useDispatch();
    const [maintenanceErrorsThrown, setMaintenanceErrorsThrown] =
        useState(false);

    const { accounts, instance: msalInstance } = useMsal();

    useEffect(() => {
        if (!maintenanceErrorsThrown && servicesInMaintenanceMode) {
            servicesInMaintenanceMode.forEach((service) =>
                triggerSnackbar(
                    "warning",
                    `${serviceNames[service]} is down for maintenance`,
                    null,
                    dispatch
                )
            );
            setMaintenanceErrorsThrown(true);
        }
    }, [maintenanceErrorsThrown, servicesInMaintenanceMode]);

    const onIdle = async () => {
        const logoutRequest = {
            account: msalInstance.getAccountByHomeId(accounts[0].homeAccountId),
            postLogoutRedirectUri:
                msalInstance.getConfiguration().auth.redirectUri,
        };
        await msalInstance.logoutRedirect(logoutRequest);
    };

    const onPrompt = () => {
        setIsPrompted(true);
        const id = setInterval(() => {
            setRemainingTime(millisToMinutesAndSeconds(getRemainingTime()));
        }, 1000);
        setIntervalId(id);
    };

    const resetIdle = () => {
        setIsPrompted(false);
        if (intervalId !== null) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
        reset();
    };

    const { reset, getRemainingTime } = useIdleTimer({
        onIdle,
        timeout: 480 * 60 * 1000,
        promptBeforeIdle: 5 * 60 * 1000,
        onPrompt,
    });

    return (
        <ErrorBoundary
            errorState={errorState}
            handleErrors={handleErrors}
            resetErrorState={resetErrors}
            fallback={<GenericErrorPage appName={APP_NAME} />}
            location={window.location}
        >
            {!hasLoaded ? (
                <LoadingScreen appName={APP_NAME} />
            ) : (
                <ThemeProvider theme={selectedTheme}>
                    <CssBaseline />
                    <Layout
                        headerBarContent={<SfHeaderBarContent />}
                        navBarContent={<SfNavigationBar />}
                        bottomNavBar={<SfBottomNavigationBar />}
                        desktopContentSX={{ paddingRight: 0, paddingLeft: 0 }}
                        mobileContentSX={{
                            paddingRight: 0,
                            paddingLeft: 0,
                            paddingTop: 0,
                        }}
                    >
                        <PageRouter />
                    </Layout>
                    <SfSnackBar />
                    <IdleTimeoutDialog
                        open={isPrompted}
                        remainingTime={remainingTime}
                        onClick={resetIdle}
                    />
                    <ChatBotDrawer />
                    <NpsDrawer />
                    <NpsBanner />
                    <NpsButton />
                </ThemeProvider>
            )}
        </ErrorBoundary>
    );
}

export default withAppInsights(App);
