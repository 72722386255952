import {
    AMENDMENT_SUBMITTED,
    APPROVED,
    ATTENTION_REQUIRED,
    AWAITING_APPROVAL,
    CANCELLATION_SUBMITTED,
    CANCELLED,
    CREATED,
    FAILED,
    FULLY_RECEIPTED,
    IN_PROCESS,
    INCIDENT_CANCELLED,
    INCIDENT_CLOSED,
    INCIDENT_IN_PROGRESS,
    INCIDENT_NEW,
    INCIDENT_ON_HOLD,
    INCIDENT_RESOLVED,
    INCOMPLETE,
    INVALID_REQUISITION,
    NOT_RECEIPTED,
    PARTIALLY_RECEIPTED,
    PENDING,
    PO_APPROVAL_PENDING,
    PO_NOT_AVAILABLE,
    PRE_APPROVED,
    RECEIPTING_IN_FLIGHT,
    RECEIPTING_NOT_REQUIRED,
    REJECTED,
    RETURNED,
    SN_REQUEST_APPROVED,
    SN_REQUEST_CLOSED_CANCELLED,
    SN_REQUEST_CLOSED_COMPLETE,
    SN_REQUEST_CLOSED_INCOMPLETE,
    SN_REQUEST_CLOSED_REJECTED,
    SN_REQUEST_PENDING_APPROVAL,
    SUBMITTED,
    UNKNOWN_STATUS,
} from "../../constants/generalConstants";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

const statusChipContent = {
    [APPROVED]: {
        color: "success",
        label: APPROVED,
    },
    [AWAITING_APPROVAL]: {
        color: "success",
        label: AWAITING_APPROVAL,
    },
    [CANCELLED]: {
        color: "error",
        label: CANCELLED,
    },
    [CANCELLATION_SUBMITTED]: {
        color: "error",
        label: CANCELLATION_SUBMITTED,
    },
    [CREATED]: {
        color: "success",
        label: CREATED,
    },
    [INCOMPLETE]: {
        color: "warning",
        label: INCOMPLETE,
    },
    [IN_PROCESS]: {
        color: "info",
        label: IN_PROCESS,
    },
    [INVALID_REQUISITION]: {
        color: "error",
        label: "INVALID",
    },
    [PO_APPROVAL_PENDING]: {
        color: "warning",
        label: PO_APPROVAL_PENDING,
    },
    [PENDING]: {
        color: "warning",
        label: PENDING,
    },
    [SUBMITTED]: {
        color: "success",
        label: SUBMITTED,
    },
    [AMENDMENT_SUBMITTED]: {
        color: "success",
        label: AMENDMENT_SUBMITTED,
    },
    [FAILED]: {
        color: "error",
        label: FAILED,
    },
    [FULLY_RECEIPTED]: {
        color: "success",
        label: FULLY_RECEIPTED,
    },
    [RECEIPTING_NOT_REQUIRED]: {
        color: "info",
        label: RECEIPTING_NOT_REQUIRED,
    },
    [PRE_APPROVED]: {
        color: "success",
        label: PRE_APPROVED,
    },
    [PO_NOT_AVAILABLE]: {
        color: "warning",
        label: PO_NOT_AVAILABLE,
    },
    [NOT_RECEIPTED]: {
        color: "warning",
        label: NOT_RECEIPTED,
    },
    [PARTIALLY_RECEIPTED]: {
        color: "warning",
        label: PARTIALLY_RECEIPTED,
    },
    [RECEIPTING_IN_FLIGHT]: {
        color: "warning",
        label: RECEIPTING_IN_FLIGHT,
    },
    [ATTENTION_REQUIRED]: {
        color: "error",
        label: ATTENTION_REQUIRED,
    },
    [RETURNED]: {
        color: "warning",
        label: RETURNED,
    },
    [REJECTED]: {
        color: "error",
        label: REJECTED,
    },
    [SN_REQUEST_PENDING_APPROVAL]: {
        color: "info",
        label: SN_REQUEST_PENDING_APPROVAL,
    },
    [SN_REQUEST_CLOSED_COMPLETE]: {
        color: "success",
        label: SN_REQUEST_CLOSED_COMPLETE,
    },
    [SN_REQUEST_CLOSED_INCOMPLETE]: {
        color: "error",
        label: SN_REQUEST_CLOSED_INCOMPLETE,
    },
    [SN_REQUEST_CLOSED_CANCELLED]: {
        color: "error",
        label: SN_REQUEST_CLOSED_CANCELLED,
    },
    [SN_REQUEST_CLOSED_REJECTED]: {
        color: "error",
        label: SN_REQUEST_CLOSED_REJECTED,
    },
    [SN_REQUEST_APPROVED]: {
        color: "info",
        label: "In Progress",
    },
    [INCIDENT_NEW]: {
        color: "info",
        label: INCIDENT_NEW,
    },
    [INCIDENT_IN_PROGRESS]: {
        color: "info",
        label: INCIDENT_IN_PROGRESS,
    },
    [INCIDENT_ON_HOLD]: {
        color: "warning",
        label: INCIDENT_ON_HOLD,
    },
    [INCIDENT_RESOLVED]: {
        color: "success",
        label: INCIDENT_RESOLVED,
    },
    [INCIDENT_CLOSED]: {
        color: "success",
        label: INCIDENT_CLOSED,
    },
    [INCIDENT_CANCELLED]: {
        color: "error",
        label: INCIDENT_CANCELLED,
    },
    [UNKNOWN_STATUS]: {
        color: "info",
        label: UNKNOWN_STATUS,
    },
};

const getStatusChipContent = (status) => {
    if (!statusChipContent[status]) {
        return statusChipContent[UNKNOWN_STATUS];
    }
    return statusChipContent[status];
};

export const getStatusChip = (status) => {
    const props = getStatusChipContent(status);
    return (
        <Chip
            color={props.color}
            size="small"
            label={
                <Typography sx={{ whiteSpace: "normal", textAlign: "center" }}>
                    {props.label}
                </Typography>
            }
            sx={{ height: "100%" }}
        />
    );
};
