import { alpha } from "@mui/material";
import { highContrastBorderSX } from "@sse-digital-services/design-system";

export const styledDashboardTileSX = {
    ...highContrastBorderSX,
    boxShadow: "10",
    overflow: "visible",
    userSelect: "none",
    borderRadius: "1px",
};

export const styledSolidTileRootSX = {
    ...styledDashboardTileSX,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: "background.paper",
    minHeight: "60px",
    height: "100%",
};

export const styledQuickActionTileSX = {
    ...styledSolidTileRootSX,
    boxShadow: 0,
    color: "common.white",
    margin: "10px 0px",
};

export const quickActionOverlaySX = {
    position: "absolute",
    top: 10,
    left: 0,
    height: "calc(100% - 20px)",
    width: "100%",
};

export const quickActionTileButtonSX = (isDesktop) => ({
    padding: isDesktop ? "16px" : "10px 6px",
    width: "100%",
    height: "100%",
});

export const styledSolidTileRootEditingSX = (theme) => ({
    ...styledSolidTileRootSX,
    backgroundColor: alpha(theme.palette.background.paper, 0.5),
    cursor: "grab",
    ":active": {
        cursor: "grabbing",
    },
});

export const styledSolidTileHeaderSX = {
    color: "text.secondary",
    height: "20px",
    padding: "0",
};

export const styledSolidTileHeaderEditingSX = (theme) => ({
    ...styledSolidTileHeaderSX,
    color: alpha(theme.palette.text.secondary, 0.5),
});

export const styledSolidTileContentSX = {
    justifyContent: "space-evenly",

    alignItems: "center",
    color: "text.secondary",
    textAlign: "center",
    // // Specificity required to override built-in MUI padding
    "&:last-child": {
        paddingBottom: "20px",
    },
    padding: "20px",
};

export const styledSolidTileContentEditingSX = {
    ...styledSolidTileContentSX,
    opacity: 0.5,
};

export const styledDashboardTileActionButtonSX = {
    fontSize: "1.3rem",
    color: "secondary.contrastText",
    backgroundColor: "common.white",
    borderRadius: "50%",
    zIndex: "fab",
};

export const styledNotificationCardSX = {
    textAlign: "left",
    padding: "10px",
    borderRadius: "1px",
    textColor: "text.emphasis",
    minHeight: "30px",
    boxShadow: "none",
    ":hover svg": {
        opacity: 1,
    },
    ":hover .MuiCardActionArea-focusHighlight": {
        opacity: 0,
    },
};

export const styledCarouselCardSX = {
    ...highContrastBorderSX,
    p: "24px",
    textAlign: "left",
    boxShadow: "3px 3px 6px 1px rgba(0, 0, 0, 0.2)",
    minHeight: "180px",
    borderRadius: "1px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    color: "text.primary",
};
